<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="keywords" content="salbero, informatii, contact, gard, relatii, cu, clientii, numar, telefon, gorj
                                   fantana arteziana, boltari, targu jiu, borduri, pavele, copacioasa, scoarta"/>
    <meta name="description" content="Contacteaza-ne pentru mai multe informatii si comenzi la numerele de telefon:
                                      0729 352 586 si 0729 333 207 sau la adresa de email salbero_srl@yahoo.com">
    <meta name="author" content="Salbero S.R.L.">
    <title>Contact | Salbero - Producator garduri, boltari, fantani arteziene, pavele si alte prefabricate din beton </title>
</head>

<body>
    <div class="container">
        <div class="row">

            <!-- Contacteaza-ne -->
            <div class="col-md-4 col-xs-6">
                <img class="col-icon" src="../../../assets/logo-phone.png" alt="telefon">
                <h2>Contactează-ne</h2>

                <p class="find-us-paragraph">
                    <i class="fa fa-phone"></i>
                    <a class="contact" href="tel:+40729333207">+40 729 333 207</a>
                </p>
                <p class="find-us-paragraph">
                    <i class="fa fa-phone"></i>
                    <a class="contact" href="tel:+40729352586">+40 729 352 586</a>
                </p>
                <p class="find-us-paragraph">
                    <i class="fa fa-envelope"></i>
                    <a class="contact" href="mailto:salbero_srl@yahoo.com">salbero_srl@yahoo.com</a>
                </p>
            </div>

            <!-- Viziteaza-ne -->
            <div class="col-md-4 col-xs-6">
                <img class="col-icon" src="../../../assets/logo-location.png" alt="locatie">
                <h2>Vizitează-ne</h2>

                <p class="find-us-paragraph place">Salbero Copăcioasa</p>
                <address> Copăcioasa nr. 40, loc. Scoarța</address>
                <p  class="find-us-paragraph place">Salbero Târgu-Jiu</p>
                <address> Strada Lotrului, Târgu Jiu</address>
            </div>

            <!-- Program -->
            <div class="col-md-4 col-xs-6">
                <img class="col-icon" src="../../../assets/logo-clock.png" alt="program de lucru">
                <h2>Program</h2>
                <p class="find-us-paragraph">Luni-Vineri: 8:00 - 17:00</p>
                <p class="find-us-paragraph">Sâmbătă: 8:00 - 13:00</p>
            </div>
        </div>
    </div>
</body>
