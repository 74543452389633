<!----------Footer----------->
<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="widget col-xl-6">
                <h4 class="widget-title">Calitate fara compromis</h4>
                <p class="salbero-footer-description">Activăm pe piața materialelor de construcție din anul 1999. Continuăm să ne îmbunătățim constant produsele și serviciile pentru a fi la curent cu cele mai recente tehnologii și tendințe din industrie. Ne asigurăm că fiecare client beneficiază de cele mai eficiente soluții pentru nevoile sale specifice.</p>
            </div>
            <div class="widget col-xl-3 col-md-5">
                <h4 class="widget-title">Contacteaza-ne</h4>
                <ul class="list-unstyled quick-links">
                    <li><i class="bi bi-telephone-fill"></i><a class="phone" href="tel:+40729352586"> +40 729 352 586</a></li>
                    <li><i class="bi bi-telephone-fill"></i><a class="phone" href="tel:+40729333207"> +40 729 333 207</a></li>
                    <li><i class="bi bi-envelope-fill"></i> <a href="mailto:salbero_srl@yahoo.com" target="_blank"> salbero_srl@yahoo.com</a></li>
                    <li><i class="bi bi-facebook"></i> <a href="https://www.facebook.com/Salbero/"> Facebook</a></li>
                </ul>
            </div>
            <div class="widget col-md-3">
                <ul class="list-unstyled quick-links">
                    <li><a [routerLink]="['']"> Acasă</a></li>
                    <li><a routerLink="produse"> Produse</a></li>
                    <li><a routerLink="despre-noi"> Despre noi</a></li>
                    <li><a routerLink="contact"> Contact</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<div class="container footer-copyright">
    <div>
        <div>
            <p class="h6">Copyright &copy; Salbero S.R.L. - Reg. Com. J18/60/2002</p>
        </div>
    </div>
</div>
