<div class="container products-wrapper">
    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-center">
        <div *ngFor="let product of resolveProducts()" class="col product-card">
            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h5 class="my-0 fw-normal">{{product.name}}</h5>
                </div>
                <div class="card-body">
                    <img src="{{product.urlImage[0]}}" class="img-fluid" alt="..." [routerLink]="['', translateProductNameInLink(product)]" (click)="goItem(product)">
                    <p class="product-price">{{product.price}}<small class="text-body-secondary fw-light"> {{product.priceUnitMeasure}}</small></p>
                    <button type="button" class="w-100 btn btn-lg btn-primary" [routerLink]="['', translateProductNameInLink(product)]" (click)="goItem(product)">Vezi detalii</button>
                </div>
            </div>
        </div>
    </div>
</div>