import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-despre-noi',
  templateUrl: './despre-noi.component.html',
  styleUrls: ['./despre-noi.component.css']
})
export class DespreNoiComponent implements OnInit {
  screenWidth = window.innerWidth;
  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
