import {Card} from './home-cards-structure';

export const homeCardsList: Card[] = [
    {
        urlImage: '../../../assets/products/acasa_gard_600.webp',
        alt: 'gard',
        name: 'Gard',
        description: 'Placi de gard moderne si durabile produse din ciment superior si armate cu fier beton',
    },
    {
        urlImage: '../../../assets/products/acasa_spalieri_600.webp',
        alt: 'spalier',
        name: 'Spalieri / Stalpi',
        description: 'Spalierii sunt armați cu fier beton Ø6mm fiind ideali pentru susținerea viței de vie și pentru garduri',
    },
    {
        urlImage: '../../../assets/products/acasa_boltar_600.webp',
        alt: 'boltar',
        name: 'Boltari',
        description: 'Boltari din ciment performant ce ofera rezistenta superioara gardurilor si peretilor',
    },
    {
        urlImage: '../../../assets/products/acasa_pavea_600.webp',
        alt: 'pavele',
        name: 'Pavele',
        description: 'Pavele perfecte atat pentru pavaj pietonal cat si pentru cel rutier',
    },
    {
        urlImage: '../../../assets/products/acasa_bordura_600.webp',
        alt: 'borduri',
        name: 'Borduri',
        description: 'Delimitarea aleilor nu a fost niciodata mai simpla. Alege din gama noastra variata de culori si dimensiuni',
    },
    {
        urlImage: '../../../assets/products/acasa_capac_600.webp',
        alt: 'capac',
        name: 'Capace si Coame gard',
        description: 'Descopera accesoriile ideale pentru gard si alege culoarea si dimensiunea potrivita pentru gardul tau',
    },
    {
        urlImage: '../../../assets/products/acasa_fantana_600.webp',
        alt: 'fantana',
        name: 'Fantani Arteziene',
        description: 'Creeaza o atmosfera deosebita in gradina cu o fantana arteziana',
    },
    {
        urlImage: '../../../assets/products/acasa_piatra_600.webp',
        alt: 'piatra',
        name: 'Piatra Decorativa',
        description: 'Elemente pentru placarea fatadelor exterioare create sa ofere un aspect unic',
    },
]