import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SharedService} from '../../SharedService';
import {homeCardsList} from 'src/app/home-cards-list';

@Component({
    selector: 'app-acasa',
    templateUrl: './acasa.component.html',
    styleUrls: ['./acasa.component.css']
})
export class AcasaComponent implements OnInit {
    list = homeCardsList;
    constructor(private service: SharedService, private router: Router) {
    }

    ngOnInit(): void {
        window.scrollTo(0, 0);
    }
    setTheme(theme: string) {
      this.service.setLink(theme);
    }

    getList():any {
        console.log(this.list);
        return this.list;
    }
}
