import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {
  screenWidth = window.innerWidth;
  constructor() {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}

