<div class="container col-xxl-8 px-4">
    <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-10 col-sm-8 col-lg-6">
            <img src="../../../assets/materiale_constructie.png" class="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
        </div>
        <div class="col-lg-6">
            <h1 class="display-5 fw-bold text-body-emphasis lh-1 mb-3">Salbero - partenerul tău de încredere</h1>
            <p class="lead">Oferim soluții durabile și eficiente în producerea de materiale de construcții din beton. Cu o experiență de peste 20 de ani în industrie, suntem specializați în producția de garduri, bolțari, coame, pavaje și fântâni arteziene.</p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                <button type="button" class="btn btn-primary btn-lg px-4 me-md-2" routerLink="contact">Contactează-ne</button>
                <button type="button" class="btn btn-outline-secondary btn-lg px-4" routerLink="despre-noi">Despre noi</button>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-xl-12 header-search">
            <app-search></app-search>
        </div>
    </div>
</div>

<div class="container cards-container">
    <div class="row justify-content-around">
        <div *ngFor="let card of getList()" class="col-md-4 product-container"
             [routerLink]="['/produse', card.alt]" routerLinkActive="active" (click)="setTheme(card.alt)">
            <app-home-card [card]="card"></app-home-card>
        </div>
    </div>
</div>
