<div class="flip-card" (mouseenter) = "onHover = true" (mouseleave) = "onHover = false">
    <div class="card-img-overlay flip-card-inner">
        <div class="flip-card-front">
            <img class="img-product" src="{{card.urlImage}}" alt="{{card.alt}}"
                 [ngClass]="onHover ? 'card-hovered' : ''"/>
            <div class="card-img-overlay ">
                <h4 class="product-title">{{card.name}}</h4>
            </div>
        </div>
        <div class="flip-card-back d-flex justify-content-center align-items-center">
            <p class="product-description">{{card.description}}</p>
        </div>
    </div>
</div>