<div class="about-wrapper">
<div class="container">
    <div class="row">
        <div class="col-md-6">
            <h5 class="title">Cine suntem</h5>
            <p>Încă din anul 1999 oferim clienților noștri o gama largă și diversificată de produse din beton de calitate destinate casei și grădinii tale.
                Am învățat multe în cei peste 20 de ani de când activăm și asta ne ajută să îți oferim produse care rezistă în timp.
            </p>
            <p>Materialele de construcții ne pasionează și căutăm încontinuu să inovăm pentru a-ți oferi cea mai bună
                experiență în proiectul tău de construcție.
                Ne dorim să pleci mulțumit, să revii cu plăcere și să ne recomanzi cu încredere.
            </p>
        </div>
        <div class="col-md-6 text-center">
            <img width="400" src="../../../assets/about-us-team.webp" alt="echipa lucrand">
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-6 order-md-first order-last text-center">
            <img [width]="400" src="../../../assets/about-us-values.webp" alt="maini unite">
        </div>
        <div class="col-md-6 order-md-first">
            <h5 class="title">Valorile noastre</h5>
            <div>Suntem onești pentru a putea fi sigur că îți livrăm exact ceea ce ți-am promis.</div>
            <div>Progresul continuu ne caracterizează pentru că tu să fii tot mai mulțumit, în fiecare zi.</div>
            <div>Punem clientul pe primul loc și ne dorim să ai o experiență cât mai plăcută pentru a simți că ești
                cea mai importantă componentă a companiei noastre.</div>
        </div>
    </div>
</div>
</div>