<nav class="navbar navbar-expand-lg rounded" aria-label="Thirteenth navbar example">
    <div class="container">
        <a class="navbar-brand logo" href=""><img src="../../../assets/navbar_logo.png" height="50" alt="Salbero logo"></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Salbero</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body d-lg-flex">
                <ul class="navbar-nav col-lg-10 justify-content-lg-center">
                    <li class="nav-item">
                        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:
true}" aria-current="page" routerLink="/" data-bs-dismiss="offcanvas">Home</a>
                    </li>
                    <li ngbDropdown class="nav-item dropdown" (mouseenter)="setDropdown(true)"
                        (mouseleave)="setDropdown(false)">
                        <a class="nav-link dropdown-toggle" [routerLink]="['produse']"
                           (click)="setTheme('garduri')" [routerLinkActive]="['active']" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Produse
                        </a>
                        <ul ngbDropdownMenu class="{{dropdownClass}}" (click)="setDropdown(false)">
                            <li><a class="dropdown-item" [routerLink]="['produse','garduri']"
                                   (click)="setTheme('garduri')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Gard</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse','spalieri']"
                                   (click)="setTheme('spalieri')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Stalpi/Spalieri</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse','boltari']"
                                   (click)="setTheme('boltar')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Boltari</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse', 'pavele']"
                                   (click)="setTheme('pavele')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Pavele</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse', 'borduri']"
                                   (click)="setTheme('borduri')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Borduri</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse', 'capace']"
                                   (click)="setTheme('capace')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Capace si Coame gard</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse', 'fantana']"
                                   (click)="setTheme('fantana')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Fantană Arteziană</a></li>
                            <li><a class="dropdown-item" [routerLink]="['/produse', 'piatra']"
                                   (click)="setTheme('piatra')" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Piatră Decorativă</a></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="despre-noi" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Despre
                            noi</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="contact" [routerLinkActive]="['active']" data-bs-dismiss="offcanvas">Contact</a>
                    </li>
                </ul>
                <div class="d-lg-flex col-lg-2 justify-content-lg-end">
                    <a href="tel:+40729333207" class="btn btn-primary" tabindex="-1" role="button" >Suna acum</a>
                </div>
            </div>
        </div>
    </div>
</nav>
